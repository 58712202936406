.aboutme .details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5rem 10% 0rem 10%;
  box-sizing: border-box;
}
.aboutme .details .section1 > div {
  padding: 0.5rem 0rem;
}
.aboutme .details .section1 > div:nth-last-child(2) {
  margin-bottom: 1rem;
}
.aboutme .details .content {
  color: var(--highlightColor);
}
.aboutme .details .section1 a {
  text-decoration: none;
  cursor: pointer;
  background-color: var(--secondaryColor);
  text-align: center;
  color: var(--fontColor);
  padding: 0.2rem 1.5rem;
  border-radius: 3rem;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--primaryColor),
    -0.1rem -0.1rem 0.3rem var(--highlightTransparentColor);
}
.aboutme .details .boxes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
}
.aboutme .details .boxes > span {
  color: var(--fontColor);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10rem;
  height: 10rem;
  background-color: var(--secondaryColor);
  border-radius: 0.2rem;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--primaryColor),
    -0.1rem -0.1rem 0.3rem var(--highlightTransparentColor);
}
.aboutme .details .boxes > span:hover,
.aboutme .details .section1 span:hover {
  background-color: var(--gradientColor1);
  background-image: linear-gradient(
    315deg,
    var(--gradientColor1) 0%,
    var(--gradientColor2) 74%
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 5s ease infinite;
  -moz-animation: gradient 5s ease infinite;
  animation: gradient 5s ease infinite;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--primaryColor),
    -0.1rem -0.1rem 0.3rem var(--highlightTransparentColor);
}
.aboutme .details .section1 span:hover {
  box-shadow: unset;
}
.aboutme .details .boxes > span:hover .content {
  color: var(--fontColor);
}
.aboutme .details .boxes > span .content {
  font-size: 2rem;
}
@media screen and (max-width: 1350px) {
  .aboutme .details {
    height: 100%;
  }
}
@media screen and (max-width: 500px) {
  .aboutme .details {
    flex-direction: column;
  }
  .aboutme .details .boxes {
    margin: 1rem 0rem;
    flex-direction: row;
  }
  .aboutme .details .boxes > span {
    min-width: 8rem;
    height: 8rem;
  }
}
