/* .landing-page {
  display: grid;
  grid-template-columns: 20rem auto;
  overflow: hidden;
  height: 100vh;
}
.landing-page .sections {
  overflow-y: auto;
  padding: 10px;
}
@media screen and (max-width: 800px) {
  .landing-page {
    display: flex;
  }
}
@media screen and (max-width: 500px) {
  .landing-page .sections {
    padding: 5px;
  }
} */
.main {
  overflow: hidden;
}
.main .landing-section {
  overflow-y: auto;
  padding: 0 10px;
}
@media screen and (max-width: 500px) {
  .main .landing-section {
    padding: 5px;
  }
}
