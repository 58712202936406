.sidenav {
  z-index: 3;
  padding-top: 0.5rem;
  background-color: var(--secondaryColor);
  display: none;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-y: scroll;
}
.sidenav .menu-icon {
  display: none;
  color: var(--fontColor);
  padding-bottom: 1rem;
}
.sidenav .dark-mode {
  cursor: pointer;
  align-self: flex-end;
}
.sidenav .profile-pic {
  width: 150px;
  height: 150px;
  min-height: 150px;
  border-radius: 50%;
  border: solid var(--highlightColor) 0.2rem;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Add transition for smooth animation */
}

.sidenav .profile-pic:hover {
  transform: scale(1.05); /* Scale up the image when hovering */
  box-shadow: 0 0 2px var(--highlightColor), 0 0 4px var(--highlightColor),
    0 0 6px var(--highlightColor); /* Add glowing effect to the border */
}

.sidenav .profile-pic img {
  width: 100%;
}
.sidenav .name {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0.2rem;
}
.sidenav .section-btns {
  margin-top: 1rem;
  text-align: center;
}
.sidenav .section-btns > span {
  text-decoration: none;
  color: var(--fontColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.2rem 0rem;
  background-color: var(--defaultBtnColor);
  padding: 0.5rem 4rem;
  border-radius: 3rem;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--primaryColor),
    -0.1rem -0.1rem 0.3rem var(--highlightTransparentColor);
}
.sidenav .section-btns > span:hover {
  color: var(--fontColor);
  background-color: var(--gradientColor1);
  background-image: linear-gradient(
    315deg,
    var(--gradientColor1) 0%,
    var(--gradientColor2) 74%
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 5s ease infinite;
  -moz-animation: gradient 5s ease infinite;
  animation: gradient 5s ease infinite;
}
.sidenav .social-login {
  display: flex;
  margin-top: 0.5rem;
}
.sidenav .social-login .social-media-icon {
  cursor: pointer;
  margin: 0.3rem;
}
.sidenav .social-login .social-media-icon.instagram:hover {
  color: var(--instagram);
}
.sidenav .social-login .social-media-icon.linkedin:hover {
  color: var(--linkedIn);
}

/* dim background */

.dim-background {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
}
.dim-background.hide {
  display: none;
}
.dim-background.active {
  display: block;
}
@media screen and (max-width: 850px) {
  .sidenav {
    display: flex;
    width: 20rem;
    position: fixed;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    left: -20rem;
    transition: left 200ms linear;
    transition: all 0.5s ease;
  }
  .sidenav.active {
    left: 0rem;
  }
  .sidenav.hide {
    left: -20rem;
  }
  .sidenav .menu-icon {
    cursor: pointer;
    position: fixed;
    display: unset;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
}
