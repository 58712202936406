/* theme 🌗*/
:root {
  --primaryColor: #000000;
  --primaryBackgroundColor: #222222;
  --secondaryColor: #333333;
  --highlightColor: #88ca5e;
  --highlightTransparentColor: #87ca5e49;
  --defaultBtnColor: #494949;
  --fontColor: #ffffff;
  --iconColor: #131313;
  --gradientColor1: #2a2a72;
  --gradientColor2: #004181;
  --instagram: #e1306c;
  --linkedIn: #0077b5;
}
.light-theme {
  --primaryColor: #ffffff;
  --primaryBackgroundColor: #dddddd;
  --secondaryColor: #cccccc;
  --highlightColor: #057dcd;
  --highlightTransparentColor: #057dcd49;
  --defaultBtnColor: #b6b6b6;
  --fontColor: #000000;
  --iconColor: #ececec;
  --gradientColor1: #d5d58d;
  --gradientColor2: #ffbe7e;
  --instagram: #e1306c;
  --linkedIn: #0077b5;
}
.dark-theme {
  --primaryColor: #000000;
  --primaryBackgroundColor: #222222;
  --secondaryColor: #333333;
  --highlightColor: #88ca5e;
  --highlightTransparentColor: #87ca5e49;
  --defaultBtnColor: #494949;
  --fontColor: #ffffff;
  --iconColor: #131313;
  --gradientColor1: #2a2a72;
  --gradientColor2: #004181;
  --instagram: #e1306c;
  --linkedIn: #0077b5;
}

body {
  background-color: var(--primaryBackgroundColor);
  color: var(--fontColor);
  font-size: 1.3rem;
  scroll-behavior: smooth;

  transition: all 0.5s ease;
}
/* selection */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* scroll */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: var(--secondaryColor);
}

::-webkit-scrollbar-thumb {
  background: var(--highlightColor);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--fontColor);
}
@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
@-webkit-keyframes gradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes gradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes gradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
