.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 10%;
}
.home .profile-name {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.home .profile-name .name {
  color: var(--highlightColor);
}
.home .about-me {
  margin-bottom: 2rem;
  text-align: justify;
}
.home .about-me-btn {
  text-decoration: none;
  color: var(--fontColor);
  cursor: pointer;
  background-color: var(--defaultBtnColor);
  width: 10rem;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border-radius: 3rem;
  box-shadow: 0.2rem 0.2rem 0.5rem var(--primaryColor),
    -0.1rem -0.1rem 0.3rem var(--highlightTransparentColor);
}
.home .about-me-btn:hover {
  background-color: var(--gradientColor1);
  background-image: linear-gradient(
    315deg,
    var(--gradientColor1) 0%,
    var(--gradientColor2) 74%
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 5s ease infinite;
  -moz-animation: gradient 5s ease infinite;
  animation: gradient 5s ease infinite;
  box-shadow: unset;
}
