.awards .details {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr)); */
  margin: 2rem;
  justify-items: center;
  align-items: center;
}
.awards .details > div {
  width: 25rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryColor);
  border-radius: 0.3rem;
  box-sizing: border-box;
  padding: 2rem;
  box-shadow: 0.3rem 0.3rem 0.5rem var(--primaryColor),
    -0.2rem -0.2rem 0.4rem var(--highlightTransparentColor);
}
.awards .details > div:hover {
  background-color: var(--gradientColor1);
  background-image: linear-gradient(
    315deg,
    var(--gradientColor1) 0%,
    var(--gradientColor2) 74%
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 5s ease infinite;
  -moz-animation: gradient 5s ease infinite;
  animation: gradient 5s ease infinite;
  box-shadow: 0.3rem 0.3rem 0.5rem var(--primaryColor),
    -0.2rem -0.2rem 0.4rem var(--highlightTransparentColor);
}
.awards .details > div > div {
  padding: 0.5rem 0rem;
}
.awards .details .title {
  font-weight: 500;
  font-size: 1.2rem;
}
.awards .details .date {
  font-size: 0.8rem;
  width: 10rem;
  padding: 0.3rem;
  text-align: center;
  background-color: var(--primaryColor);
  border-radius: 2rem;
}
.awards .details .title {
  font-weight: 500;
  font-size: 1.4rem;
}
.awards .details .content {
  text-align: justify;
  line-height: 1.7rem;
}
@media screen and (max-width: 1350px) {
  .awards .details {
    height: 100%;
  }
  .awards .details > div {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 500px) {
  .awards .details {
    margin: 0.5rem;
  }
  .awards .details > div {
    padding: 1rem;
  }
}
